import api from './api'

const endpoints = {
  strategy_area: 'strategy-area/',
  reset_strategy_area: 'reset-strategy-area/',
  strategy_attribute: 'strategy-attribute/',
  reset_strategy_attribute: 'reset-strategy-attribute/',
  strategy_action: 'strategy-action/',
  reset_strategy_action: 'reset-strategy-action/',
  advice_strategy: 'advice-strategy/',
  strategy_history: 'strategy-history/',
  reset_advice_strategy: 'reset-advice-strategy/',
  advice_strategy_save: 'strategy-save/',
  advice_doc_config: 'advice-doc-config/',
  benefit_risk: 'benefit-risk/',
  implementation_step: 'implementation-step/',
  asset_portfolio_option: 'portfolio-option-history/', // history
  asset: 'asset-history/', // history
  liability: 'liability-history/' , // history
}

const getStrategyArea = async (params) => {
  return api.get(endpoints.strategy_area, { params })
}

const getDetailStrategyArea = async (id) => {
  return api.get(endpoints.strategy_area + id + '/')
}

const getResetStrategyArea = async (id) => {
  return api.get(endpoints.reset_strategy_area + id + '/')
}

const saveStrategyArea = async (data) => {
  return api.post(endpoints.strategy_area, data)
}
const updateStrategyArea = async (data, id) => {
  return api.put(endpoints.strategy_area + id + '/', data)
}

const deleteStrategyArea = async (id) => {
  return api.delete(endpoints.strategy_area + id + '/')
}

const getStrategyAttribute = async (params) => {
  return api.get(endpoints.strategy_attribute, { params })
}

const getDetailStrategyAttribute = async (id) => {
  return api.get(endpoints.strategy_attribute + id + '/')
}

const getResetStrategyAttribute = async (id) => {
  return api.get(endpoints.reset_strategy_attribute + id + '/')
}

const saveStrategyAttribute = async (data) => {
  return api.post(endpoints.strategy_attribute, data)
}
const updateStrategyAttribute = async (data, id) => {
  return api.put(endpoints.strategy_attribute + id + '/', data)
}

const deleteStrategyAttribute = async (id) => {
  return api.delete(endpoints.strategy_attribute + id + '/')
}

const getStrategyAction = async (params) => {
  return api.get(endpoints.strategy_action, { params })
}

const getDetailStrategyAction = async (id) => {
  return api.get(endpoints.strategy_action + id + '/')
}

const getResetStrategyAction = async (id) => {
  return api.get(endpoints.reset_strategy_action + id + '/')
}

const saveStrategyAction = async (data) => {
  return api.post(endpoints.strategy_action, data)
}
const updateStrategyAction = async (data, id) => {
  return api.put(endpoints.strategy_action + id + '/', data)
}

const deleteStrategyAction = async (id) => {
  return api.delete(endpoints.strategy_action + id + '/')
}

const getAdviceStrategy = async (params) => {
  return api.get(endpoints.advice_strategy, { params })
}

const getDetailAdviceStrategy = async (id) => {
  return api.get(endpoints.advice_strategy + id + '/')
}

const getResetAdviceStrategy = async (id) => {
  return api.get(endpoints.reset_advice_strategy + id + '/')
}

const saveAdviceStrategy = async (data) => {
  return api.post(endpoints.advice_strategy, data)
}
const updateAdviceStrategy = async (data, id) => {
  return api.put(endpoints.advice_strategy + id + '/', data)
}

const deleteAdviceStrategy = async (id) => {
  return api.delete(endpoints.advice_strategy + id + '/')
}

const getAdviceStrategySaved = async (params) => {
  return api.get(endpoints.advice_strategy_save, { params })
}

const saveAdviceStrategySaved = async (data) => {
  return api.post(endpoints.advice_strategy_save, data)
}

const resetAdviceStrategySaved = async (data) => {
  return api.delete(endpoints.advice_strategy_save, { data })
}

const createAdviceStrategyHistory = async (data) => {
  return api.post(endpoints.strategy_history, data)
}

const updateAdviceStrategyHistory = async (data, id) => {
  return api.put(endpoints.strategy_history + id + '/', data)
}

const getAdviceStrategyHistory = async (params) => {
  return api.get(endpoints.strategy_history, { params })
}

const getDetailAdviceStrategyHistory = async (id) => {
  return api.get(endpoints.strategy_history + id + '/')
}

const deleteAdviceStrategyHistory = async (id) => {
  return api.delete(endpoints.strategy_history + id + '/')
}

const getAdviceDocConfig = async (params) => {
  return api.get(endpoints.advice_doc_config, { params })
}

const getDetailAdviceDocConfig = async (id) => {
  return api.get(endpoints.advice_doc_config + id + '/')
}

const getResetAdviceDocConfig = async (id) => {
  return api.get(endpoints.advice_doc_config + id + '/')
}

const saveAdviceDocConfig = async (data) => {
  return api.post(endpoints.advice_doc_config, data)
}
const updateAdviceDocConfig = async (data, id) => {
  return api.put(endpoints.advice_doc_config + id + '/', data)
}

const deleteAdviceDocConfig = async (id) => {
  return api.delete(endpoints.advice_doc_config + id + '/')
}

const getBenefitRisk = async (params) => {
  return api.get(endpoints.benefit_risk, { params })
}

const getDetailBenefitRisk = async (id) => {
  return api.get(endpoints.benefit_risk + id + '/')
}

const saveBenefitRisk = async (data) => {
  return api.post(endpoints.benefit_risk, data)
}
const updateBenefitRisk = async (data, id) => {
  return api.put(endpoints.benefit_risk + id + '/', data)
}

const deleteBenefitRisk = async (id) => {
  return api.delete(endpoints.benefit_risk + id + '/')
}

const getImplementationStep = async (params) => {
  return api.get(endpoints.implementation_step, { params })
}

const getDetailImplementationStep = async (id) => {
  return api.get(endpoints.implementation_step + id + '/')
}

const saveImplementationStep = async (data) => {
  return api.post(endpoints.implementation_step, data)
}
const updateImplementationStep = async (data, id) => {
  return api.put(endpoints.implementation_step + id + '/', data)
}

const deleteImplementationStep = async (id) => {
  return api.delete(endpoints.implementation_step + id + '/')
}

const getAssetHistoryOption = async (params) => {
  return api.get(endpoints.asset_portfolio_option, { params })
}

const getDetailAssetHistoryOption = async (id) => {
  return api.get(endpoints.asset_portfolio_option + id + '/')
}

const saveAssetHistoryOption = async (data) => {
  return api.post(endpoints.asset_portfolio_option, data)
}
const updateAssetHistoryOption = async (data, id) => {
  return api.put(endpoints.asset_portfolio_option + id + '/', data)
}

const deleteAssetHistoryOption = async (id) => {
  return api.delete(endpoints.asset_portfolio_option + id + '/')
}


const getAssetHistory = async (params) => {
  return api.get(endpoints.asset, { params })
}

const getDetailAssetHistory = async (id) => {
  return api.get(endpoints.asset + id + '/')
}

const saveAssetHistory = async (data) => {
  return api.post(endpoints.asset, data)
}
const updateAssetHistory = async (data, id) => {
  return api.put(endpoints.asset + id + '/', data)
}

const deleteAssetHistory = async (id) => {
  return api.delete(endpoints.asset + id + '/')
}

const getLiabilityHistory = async (params) => {
  return api.get(endpoints.liability, { params })
}

const getDetailLiabilityHistory = async (id) => {
  return api.get(endpoints.liability + id + '/')
}

const saveLiabilityHistory = async (data) => {
  return api.post(endpoints.liability, data)
}
const updateLiabilityHistory = async (data, id) => {
  return api.put(endpoints.liability + id + '/', data)
}

const deleteLiabilityHistory = async (id) => {
  return api.delete(endpoints.liability + id + '/')
}

export default {
  getStrategyArea,
  getResetStrategyArea,
  getDetailStrategyArea,
  saveStrategyArea,
  updateStrategyArea,
  deleteStrategyArea,
  getStrategyAttribute,
  getDetailStrategyAttribute,
  getResetStrategyAttribute,
  saveStrategyAttribute,
  updateStrategyAttribute,
  deleteStrategyAttribute,
  getStrategyAction,
  getDetailStrategyAction,
  getResetStrategyAction,
  saveStrategyAction,
  updateStrategyAction,
  deleteStrategyAction,
  getAdviceStrategy,
  getDetailAdviceStrategy,
  getResetAdviceStrategy,
  saveAdviceStrategy,
  updateAdviceStrategy,
  deleteAdviceStrategy,
  getAdviceStrategySaved,
  saveAdviceStrategySaved,
  resetAdviceStrategySaved,
  createAdviceStrategyHistory,
  getAdviceStrategyHistory,
  updateAdviceStrategyHistory,
  getDetailAdviceStrategyHistory,
  deleteAdviceStrategyHistory,
  getAdviceDocConfig,
  getDetailAdviceDocConfig,
  getResetAdviceDocConfig,
  saveAdviceDocConfig,
  updateAdviceDocConfig,
  deleteAdviceDocConfig,
  getBenefitRisk,
  getDetailBenefitRisk,
  saveBenefitRisk,
  updateBenefitRisk,
  deleteBenefitRisk,
  getImplementationStep,
  getDetailImplementationStep,
  saveImplementationStep,
  updateImplementationStep,
  deleteImplementationStep,
  getAssetHistoryOption,
  getDetailAssetHistoryOption,
  saveAssetHistoryOption,
  updateAssetHistoryOption,
  deleteAssetHistoryOption,
  getAssetHistory,
  getDetailAssetHistory,
  saveAssetHistory,
  updateAssetHistory,
  deleteAssetHistory,
  getLiabilityHistory,
  getDetailLiabilityHistory,
  saveLiabilityHistory,
  updateLiabilityHistory,
  deleteLiabilityHistory
}
