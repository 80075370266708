import { Input, Table, Button, Modal, Space, Select, Checkbox } from 'antd'
import {DeleteOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons';
import { AdminLayout } from 'Components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {AuthService, ProductService, StrategyService} from "../../services";
import {get} from "lodash";

const { Option } = Select

const InvestmentOptionTable = ({ preview, netwealth_type, advice_strategy_id, product_type, strategy_history}) => {
  const data_config = {
    type_id: netwealth_type == "asset" ? "9021be88-1c47-4ca2-88db-db88bbff227f" : "f53af184-becd-40b5-b12d-b6c60077c287",
    subtype_id: netwealth_type == "asset" ? "1272529a-27b0-4482-8e9d-970fde5258fa" : "6fadc5ea-095f-48a7-ad1f-2aa5a84432d5"
  }
  let navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [investment_options_list, setInvestmentOptionsList] = useState([])
  const [entity_data, setEntityData] = useState({})
  const [product_list, setProductList] = useState([])
  const [provider, setProvider] = useState("")
  const [advise_for_option, setAdviseForOption] = useState({})
  const [type_option, setTypeOption] = useState({})
  const [subtype_option, setSubtypeOption] = useState({})
  const [current_history, setCurrentHistory] = useState(null)


  const [isModalOpen, setIsModalOpen] = useState(false);

  const strategy_option_list = ['Auto', 'Open', 'Maintain', 'Deposit']


  const getProductList = async () => {
    //add business
    let product_type_for = {'liability_product_type': product_type}
    if (netwealth_type === "asset") {
      product_type_for = {'asset_product_type': product_type}
    }
    const res = await ProductService.getProduct({...product_type_for, netwealth_type})
    setProductList(get(res, 'data.data', []))
  }


  // State to manage table data
  const [dataSource, setDataSource] = useState([
    {
      id: 1,
      position: 'Existing',
      account: 'Account 1',
      current_balance: '5000',
      proposed_balance: '7000',
      strategy: 'Auto',
      drp: true,
      option: ''
    },
    {
      id: 2,
      position: 'New',
      account: 'Account 2',
      current_balance: '8000',
      proposed_balance: '9000',
      strategy: 'Open deposit',
      drp: false,
      option: ''
    },
  ])

  // State to manage table data
  const [dataSource1, setDataSource1] = useState([
    {
      id: 1,
      position: 'Position 1',
      investment_option: 'Account 1',
      current_balance: '5000',
      changed_balance: '5000',
      proposed_balance: '7000',
      strategy: 'Auto',
      drp: true,
      option: ''
    },
    {
      id: 2,
      position: 'Position 2',
      investment_option: 'Account 2',
      current_balance: '8000',
      changed_balance: '8000',
      proposed_balance: '9000',
      strategy: 'Open deposit',
      drp: false,
      option: ''
    },
  ])

  const getData = async () => {
      let res_netwealth_data; // Declare res outside the conditional block

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.getAssetHistory({
          advice_strategy_id: advice_strategy_id,
        });
      } else {
        res_netwealth_data = await StrategyService.getLiabilityHistory({
          advice_strategy_id: advice_strategy_id
        });
      }

      // Assuming you are using lodash's get method to safely access the data
      setDataSource(get(res_netwealth_data, 'data.data', []));
    };

  function isInteger(str) {
      return /^-?\d+$/.test(str);
    }


  const saveData = async (data) => {
    let res_netwealth_data; // Declare res outside the conditional block
    if (isInteger(data.id)){
      data['advice_strategy_id'] = advice_strategy_id
      data['strategy_history'] = strategy_history

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['provider'] && typeof data['provider'] === 'object') {
          data['provider'] = data['provider'].id;
      }

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.saveAssetHistory(data);
      } else {
        res_netwealth_data = await StrategyService.saveLiabilityHistory(data);
      }
    }else{

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['provider'] && typeof data['provider'] === 'object') {
          data['provider'] = data['provider'].id;
      }

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.updateAssetHistory(data,data.id);
      } else {
        res_netwealth_data = await StrategyService.updateLiabilityHistory(data, data.id);
      }
    }
    getData()
  };


  const saveOptionData = async (data) => {
    let res_netwealth_data; // Declare res outside the conditional block
    if (isInteger(data.id)){
      data['advice_strategy_id'] = advice_strategy_id
      data['strategy_history'] = strategy_history

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['provider'] && typeof data['provider'] === 'object') {
          data['provider'] = data['provider'].id;
      }

      if (netwealth_type === "asset") {
        data['asset_history'] = current_history
        res_netwealth_data = await StrategyService.saveAssetHistoryOption(data);
      } else {
        data['liability_history'] = current_history
        res_netwealth_data = await StrategyService.updateAssetHistoryOption(data);
      }
    }else{

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['option'] && typeof data['option'] === 'object') {
          data['option'] = data['option'].id;
      }

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.updateAssetHistoryOption(data,data.id);
      } else {
        res_netwealth_data = await StrategyService.updateAssetHistoryOption(data, data.id);
      }
    }
    getOptionData(current_history)
  };

  const getOptionData = async (obj_id) => {
    //datasource1 data
      let res_netwealth_data; // Declare res outside the conditional block

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.getAssetHistoryOption({
          asset_history: obj_id
        });
      } else {
        res_netwealth_data = await StrategyService.getAssetHistoryOption({
          liability_history: obj_id
        });
      }

      // Assuming you are using lodash's get method to safely access the data
      setDataSource1(get(res_netwealth_data, 'data.data', []));
    };

  useEffect(() => {
    getData()
    getProductList()
  }, [])



  const onclickRetain = (obj_id) =>{
    getOptionData(obj_id)
    setCurrentHistory(obj_id)
  }

  // Function to add a new record
  const handleAddRecord = () => {
    const newRecord = {
      id: dataSource.length + 1,
      exising_new: `new`,
      account: {},
      balance: 0,
      proposed_balance: 0,
      strategy: `Strategy ${dataSource.length + 1}`,
      drp: false,
      option: ''
    }
    setDataSource([...dataSource, newRecord])
  }

  const handleSaveHistory = (record) => {
    saveData(record)
  }

  const handleSaveOptionHistory = (record) => {
    saveOptionData(record)
  }

  const handleDeleteHistory = async (id) => {
    const result = window.confirm(`Are you sure to delete this ${netwealth_type} history?`)
    if (result) {
      if (netwealth_type === "asset") {
        await StrategyService.deleteAssetHistory(id)
      }else {
        await StrategyService.deleteLiabilityHistory(id)
      }
      getData()
    }
  }

  const handleAddRecord1 = () => {
    const newRecord = {
      id: dataSource.length + 1,
      exising_new: `new`,
      investment_option: {},
      changed_balance:0,
      balance: 0,
      proposed_balance: 0,
      strategy: '',
      drp: false,
      option: ''
    }
    setDataSource1([...dataSource1, newRecord])
  }

  const showModal = (obj_id) => {
    setIsModalOpen(true);
    onclickRetain(obj_id)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleProposedBalanceChange = (event, index) => {
  const updatedDataSource = [...dataSource];
  updatedDataSource[index].proposed_balance = event.target.value;
  setDataSource(updatedDataSource);
};

  const handleChangedBalanceChange = (event, index) => {
  const updatedDataSource = [...dataSource1];
  updatedDataSource[index].changed_balance = event.target.value;
  updatedDataSource[index].proposed_balance = Number(updatedDataSource[index].balance) + Number(updatedDataSource[index].changed_balance);
  setDataSource1(updatedDataSource);
  };

  const handleStrategyChange = (value, index) => {
  const updatedDataSource = [...dataSource];
  updatedDataSource[index].strategy = value;
  setDataSource(updatedDataSource);
};

  const handleStrategyOptionChange = (value, index) => {
  const updatedDataSource = [...dataSource1];
  updatedDataSource[index].strategy = value;
  setDataSource1(updatedDataSource);
 };

  const handleDRPChange = (value, index) => {
  const updatedDataSource = [...dataSource];
  updatedDataSource[index].is_drp = value;
  setDataSource(updatedDataSource);
  };

  const handleAccountChange = (value, index) => {
  const updatedDataSource = [...dataSource];
  updatedDataSource[index].account =value;
  setDataSource(updatedDataSource);
  };

  const columns = [
    {
      title: 'Position',
      dataIndex: 'exising_new',
      key: 'exising_new',
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, e, index) => (
        <div className="table_responsive_cell">
          {e.exising_new === 'existing' ? (
            e.account && e.account.subject
          ) : (
            <Select
              showSearch
              mode="single"
              name="account"
              style={{ width: '100%' }}
              placeholder="Please select account"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              value={e.account && e.account.id}
              onChange={(value) => handleAccountChange(value, index)}
            >
              {product_list.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.subject}
                </Option>
              ))}
            </Select>
          )}
        </div>
        ),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balance',
      key: 'balance'
    },
    {
      title: 'Proposed Balance',
      dataIndex: 'proposed_balance',
      key: 'proposed_balance',
       render: (text, e, index) => (
        <div className="table_responsive_cell">
          {/*{e.exising_new === 'existing' ? (*/}
          {/*  e.proposed_balance*/}
          {/*) : (*/}
          {/*  <Input*/}
          {/*        style={{ width: '100%' }}*/}
          {/*        placeholder="balance"*/}
          {/*        type="number"*/}
          {/*        name="proposed_balance"*/}
          {/*        onChange={(event) => handleProposedBalanceChange(event, index)}*/}
          {/*        value={e.proposed_balance}*/}
          {/*  />*/}
          {/*)}*/}
          <Input
                  style={{ width: '100%' }}
                  placeholder="balance"
                  type="number"
                  name="proposed_balance"
                  onChange={(event) => handleProposedBalanceChange(event, index)}
                  value={e.proposed_balance}
            />
        </div>
        ),
    },
    {
      title: 'Strategy',
      dataIndex: 'option',
      key: 'option',
      render: (text, record, index) => (
        <Select
          style={{ width: 150 }}
          placeholder="Select an option"
          value={record.strategy}
          onChange={(value) => handleStrategyChange(value, index)}
        >
          {strategy_option_list.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Investment Option',
      key: 'investment_option',
      render: (text, record) => (<>
        <Button type="link" size='small' onClick={()=>showModal(record.id)}>Retain</Button>
        <Button type="text" size='small'>Review</Button>
      </>
      ),
    },
    {
      title: 'DRP',
      dataIndex: 'drp',
      key: 'drp',
      render: (_, record, index) => (
        <Checkbox
          checked={record.is_drp}
          onChange={(e) => handleDRPChange(e.target.checked, index)}
        >
          ON
        </Checkbox>
      ),
    },
    {
      title: 'Action',
      key: 'handle_action',
      render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => handleSaveHistory(record)} icon={<SaveOutlined />} />
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => handleDeleteHistory(record.id)}
            />
          </Space>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, record, index) => (
        // Add button only on the last row
        index === dataSource.length - 1 ? (
          <Button onClick={handleAddRecord}  shape="circle" icon={<PlusOutlined />} />
        ) : null
      ),
    },
  ]

  const columns1 = [
    {
      title: 'Position',
      dataIndex: 'exising_new',
      key: 'exising_new'
    },
    {
      title: 'Investment Option',
      dataIndex: 'option',
      key: 'option',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.option && e.option.subject}
        </div>
      ),
    },
    {
      title: 'Current Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Change',
      render: (text, e, index) => (
        <div className="table_responsive_cell">
          <Input
                  style={{ width: '100%' }}
                  placeholder="balance"
                  type="number"
                  name="changed_balance"
                  onChange={(event) => handleChangedBalanceChange(event, index)}
                  value={e.changed_balance}
            />
        </div>
        )
    },
    {
      title: 'Proposed Balance',
      dataIndex: 'proposed_balance',
      key: 'proposed_balance',
       render: (text, e) => (
        <div className="table_responsive_cell">
          {e.proposed_balance}
        </div>
        ),
    },
    {
      title: 'Strategy',
      dataIndex: 'option',
      key: 'option',
      render: (text, record, index) => (
        <Select
          style={{ width: 150 }}
          placeholder="Select an option"
          value={record.strategy}
          onChange={(value) => handleStrategyOptionChange(value, index)}
        >
          {strategy_option_list.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Action',
      key: 'handle_action',
      render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => handleSaveOptionHistory(record)} icon={<SaveOutlined />} />
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => handleDeleteHistory(record.id)}
            />
          </Space>
      )
    },
    {
      title: '',
      key: 'action',
      render: (text, record, index) => (
        // Add button only on the last row
        index === dataSource1.length - 1 ? (
          <Button onClick={handleAddRecord1}  shape="circle" icon={<PlusOutlined />} />
        ) : null
      ),
    },
  ]
  

  return (
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        {/*<h1 className="flex_title">*/}
        {/*  <span className="title">Investment Options</span>{' '}*/}
        {/*</h1>*/}
        {/*<br />*/}
        <Table
          className="custom_responsive_table"
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // pagination={{
          //   showSizeChanger: false,
          //   defaultPageSize: 50,
          //   PageSize: 50,
          // }}
        />
        <Modal title="Retain/Review" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={'100%'}>
          <Table
            className="custom_responsive_table"
            rowKey="idx"
            columns={columns1}
            dataSource={dataSource1}
            pagination={false}
            // pagination={{
            //   showSizeChanger: false,
            //   defaultPageSize: 50,
            //   PageSize: 50,
            // }}
          />
        </Modal>
      </div>
  )
}

export default InvestmentOptionTable
