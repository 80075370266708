import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { Select, Button, Modal, Tabs, Input, Form, Checkbox, Row, Col, Space, Table } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './knowledge.scss'
import { AuthService, PageService, StrategyService } from '../../services'
import { get } from 'lodash'
import moment from 'moment'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import QuillEditor from '../../Components/QuillEditor'
import { OptionValue } from 'utils'
import wizard from '../Questionnaire/wizard'
import { common_date_format } from '../../utils/helpers'
import InvestmentOptionTable from "../InvestmentOption/table";

const { TabPane } = Tabs
const { Option } = Select
const CheckboxGroup = Checkbox.Group
const timing_option = OptionValue.timing_option
const implemented_option = OptionValue.implemented_option
const action_by_option = OptionValue.action_by_option

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const AdviceStrategyScenario = (props) => {
  const initialItems = {
    droppable1: [], // save for later
    droppable2: [], //interested_data
    droppable3: [], // remaining
  }

  const [detail_data, setDetailData] = useState({})
  const [state, setState] = useState(initialItems)
  const [doc_list, setDocList] = useState([])
  const [entity_data, setEntityData] = useState({})
  const [additional_info, setAdditionalInfo] = useState({
    advice_type: '',
    advice_name: '',
    advice_date: '',
  })
  const [fees_charges, setFeeCharge] = useState({
    plan_fee: 0,
    implementation_fee: 0,
    ongoing_service_fee: 0,
  })
  const [current_detail_data, setCurrentDetailData] = useState({})
  const [current_update_data, setCurrentUpdateData] = useState({})
  const [current_index, setCurrentIndex] = useState(null)
  const [edit, setEdit] = useState(false)
  const [implementation_steps_list, seImplementationStepsList] = useState([])
  const [advice_implementation_steps, setAdviceImplementationSteps] = useState([])
  const [clickedItems, setClickedItems] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [advise_for_option, setAdviseForOption] = useState({})
  const [dependants_list, setDependantList] = useState([])
  const [estate_planning, setEstatePlanning] = useState({})
  const [goals_list, setGoalList] = useState([])

  const getImplementationStepsList = async () => {
    const res = await StrategyService.getImplementationStep()
    seImplementationStepsList(get(res, 'data.data', []))
  }

  const GetAdviseForOptionsData = async (wizard_history) => {
    const res = await PageService.getAdviseForOption(entity, {
      wizard_history: wizard_history,
    })
    setAdviseForOption(get(res, 'data.data'))
  }

  const GetDependantsForOptionsData = async (wizard_history) => {
    const res = await PageService.getDependantList(entity, {
      wizard_history: wizard_history,
    })
    setDependantList(get(res, 'data.data'))
  }

  const GetEstatePlanningData = async (wizard_history) => {
    const res = await PageService.getEstatePlanning(entity, {
      wizard_history: wizard_history,
    })
    setEstatePlanning(get(res, 'data.data'))
  }

  const GetGoalForOptionsData = async (wizard_history) => {
    const res = await PageService.getGoalList(entity, {
      wizard_history: wizard_history,
    })
    setGoalList(get(res, 'data.data'))
  }

  const render_advise_for_option = (main_option = false) => {
    let options = []

    if (main_option) {
      const main_options = state.droppable2.map((item) => item.advise_for)
      const uniqueMainOptions = [...new Set([...['client1'], main_options])]
      const advice_options = Object.entries(advise_for_option).filter(([key]) =>
        uniqueMainOptions.includes(key)
      )

      options = advice_options.map(([key, value]) => (
        <Option key={key} value={key}>
          {value}
        </Option>
      ))
    } else {
      const advice_for_from_config = get(current_detail_data, 'advice_for', [])
      const advice_options = Object.entries(advise_for_option)
      options = advice_options.map(([key, value]) => {
        if (advice_for_from_config.includes(key)) {
          return (
            <Option key={key} value={key}>
              {value}
            </Option>
          )
        }
      })
    }

    return options
  }

  const additionInfoHandleChange = (e) => {
    const { name, value } = e.target
    setAdditionalInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }))
  }

  const feesChargeInfoHandleChange = (e) => {
    const { name, value } = e.target
    setFeeCharge((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }))
  }

  const onChangeAdviceType = (value, option) => {
    setAdditionalInfo((prevInfo) => ({
      ...prevInfo,
      advice_type: value,
      advice_name: additional_info.advice_name ? additional_info.advice_name : option.children,
    }))
  }

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const history_id = params.get('history')

  let entity = null
  if (entity_type === 'client') {
    entity = params.get('entity')
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(state[source.droppableId], source.index, destination.index)
      const newState = { ...state, [source.droppableId]: items }
      setState(newState)
    } else {
      const result = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination
      )
      setState({
        ...state,
        [source.droppableId]: result[source.droppableId],
        [destination.droppableId]: result[destination.droppableId],
      })
    }

    // Make API call after state update
    await onClickMainSave()
  }

  const toggleItemClicked = (id) => {
    setClickedItems((prevState) => {
      const isAlreadyClicked = prevState.includes(id)
      if (isAlreadyClicked) {
        return prevState.filter((item) => item !== id)
      } else {
        return [...prevState, id]
      }
    })
  }

  const showModal = (e, index) => {
    e.stopPropagation()
    setIsModalOpen(true)
    const detailData = get(state, 'droppable2', [])
    const dataToSet = detailData[index] || {}
    setCurrentDetailData(dataToSet)
    setCurrentIndex(index)
    setAdviceImplementationSteps(dataToSet.advice_implementation_steps || [])
    setCurrentUpdateData({
      subject: dataToSet.subject,
      intro: dataToSet.intro,
      body_text: dataToSet.body_text,
      summary: dataToSet.summary,
      pros: dataToSet.pros,
      cons: dataToSet.cons,
      advise_for: dataToSet.advise_for || 'client1',
      selected_benefits:
        dataToSet.selected_benefits && dataToSet.selected_benefits.length > 0
          ? dataToSet.selected_benefits
          : dataToSet.benefits.filter((item) => item.required === 'yes').map((item) => item.id),
      selected_risks:
        dataToSet.selected_risks && dataToSet.selected_risks.length > 0
          ? dataToSet.selected_risks
          : dataToSet.risks.filter((item) => item.required === 'yes').map((item) => item.id),
      implemented: dataToSet.implemented || '',
      implementation_date: dataToSet.implementation_date || '',
      dependants: dataToSet.dependants || [],
      goals: dataToSet.goals || [],
      intro_body_summary: dataToSet.intro_body_summary
        ? dataToSet.intro_body_summary
        : '<p>' +
          dataToSet.intro +
          '<p></p>' +
          dataToSet.body_text +
          '<p></p>' +
          dataToSet.summary +
          '</p>',
    })
  }

  const handleOk = async () => {
    setIsModalOpen(false)
    setInitialModelData()
  }

  const handleOkModalSave = async () => {
    setIsModalOpen(false)
    const detailData = get(state, 'droppable2', [])
    detailData[current_index] = current_detail_data
    setState((prevInfo) => ({
      ...prevInfo,
      droppable2: detailData,
    }))
    await onClickMainSave()
    setInitialModelData()
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setInitialModelData()
  }

  const getDetailData = async () => {
    const res = await StrategyService.getDetailAdviceStrategyHistory(history_id)
    const main_data = get(res, 'data.data', {})
    const interested_data = get(main_data, 'interested_data', [])
    const remaining_data = get(main_data, 'remaining_data', [])
    setDetailData(main_data)
    setState({
      droppable1: get(main_data, 'save_for_later_data', []),
      droppable2: [...interested_data],
      droppable3: [...remaining_data],
    })
    GetAdviseForOptionsData(main_data.wizard_history)
    GetDependantsForOptionsData(main_data.wizard_history)
    GetEstatePlanningData(main_data.wizard_history)
    GetGoalForOptionsData(main_data.wizard_history)
    setAdditionalInfo({
      advice_type: main_data.advice_type,
      advice_name: main_data.advice_name,
      advice_date: main_data.advice_date,
    })
    setFeeCharge({
      plan_fee: main_data.plan_fee,
      implementation_fee: main_data.implementation_fee,
      ongoing_service_fee: main_data.ongoing_service_fee,
    })
  }

  useEffect(() => {
    getDetailData()
    getDocConfigData()
    getImplementationStepsList()
  }, [])

  const onClickEdit = () => {
    setEdit(true)
  }

  const setInitialModelData = () => {
    setEdit(false)
    setCurrentIndex(null)
    setCurrentUpdateData({})
    setCurrentDetailData({})
    setAdviceImplementationSteps([])
  }

  const onClickUpdate = () => {
    setEdit(false)
    const update_obj = {
      ...current_detail_data,
      ...current_update_data,
      ...{ advice_implementation_steps: advice_implementation_steps },
    }
    setCurrentDetailData(update_obj)
  }

  const onChangeUpdate = (key, value) => {
    setCurrentUpdateData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const getDocConfigData = async () => {
    const entity_data = await getEntityData()
    const res = await StrategyService.getAdviceDocConfig({
      business: entity_data.business,
    })
    setDocList(get(res, 'data.data', []))
  }

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    setEntityData(new_data)
    return new_data
  }

  const onClickAdditionalSave = async () => {
    await StrategyService.updateAdviceStrategyHistory(additional_info, history_id)
  }

  const onClickFeesSave = async () => {
    await StrategyService.updateAdviceStrategyHistory(fees_charges, history_id)
  }

  const onClickMainSave = async () => {
    await StrategyService.updateAdviceStrategyHistory(
      {
        interested_data: state.droppable2,
        save_for_later_data: state.droppable1,
        remaining_data: state.droppable3,
      },
      history_id
    )
  }

  const isQuillEmpty = (html) => {
    return html === '<p><br></p>' || html === ''
  }

  const firstLetterToLowerCase = (str) => {
    if (typeof str !== 'string' || str === undefined) {
      return ''
    }
    if (str.length === 0) {
      return str
    }
    return str.charAt(0).toLowerCase() + str.slice(1)
  }

  const getImplementationText = (implemented, implementation_date) => {
    if (implemented === 'date_specific' && implementation_date) {
      return <span>{'on ' + moment(implementation_date).format('DD-MM-YYYY') + '.'}</span>
    } else if (implemented !== 'date_specific') {
      if (implemented === 'other_strategy') {
        const previous_index = current_index - 1
        const previous_obj = state.droppable2[previous_index]
        const previous_subject = previous_obj ? previous_obj.subject : ''
        return (
          <span>
            after the implementation of <b>{previous_subject}</b> strategy.
          </span>
        )
      } else {
        return <span>{firstLetterToLowerCase(implemented_option[implemented]) + '.'}</span>
      }
    }
    return null
  }

  const handleAddImplementationSteps = () => {
    setAdviceImplementationSteps([
      ...advice_implementation_steps,
      { action_by: '', timing: '', implementation_step: {} },
    ])
  }

  const handleAdviceImplementationStepsChange = (index, name, value) => {
    const new_advice_implementation_steps = advice_implementation_steps.slice()
    new_advice_implementation_steps[index][name] = value
    setAdviceImplementationSteps(new_advice_implementation_steps)
  }

  const handleRemoveImplementationSteps = (index) => {
    const new_advice_implementation_steps = advice_implementation_steps.slice()
    new_advice_implementation_steps.splice(index, 1)
    setAdviceImplementationSteps(new_advice_implementation_steps)
  }

  const implemented_columns = [
    {
      title: 'Implementation step',
      dataIndex: 'implementation_step',
      key: 'implementation_step',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Implementation step</h6>
          {get(
            implementation_steps_list.find((option) => option.id === e.implementation_step.id),
            'subject'
          )}
        </div>
      ),
    },
    {
      title: 'Action by',
      dataIndex: 'action_by',
      key: 'action_by',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Action by</h6>
          {get(action_by_option, text)}
        </div>
      ),
    },
    {
      title: 'Timing',
      dataIndex: 'timing',
      key: 'timing',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Timing</h6>
          {get(timing_option, text)}
        </div>
      ),
    },
  ]

  const render_goal = () => {
    const selected_goals = goals_list.filter((item) =>
      get(current_detail_data, 'goals', []).includes(item.value)
    )
    return (
      <ul>
        {selected_goals.map((item) => (
          <li key={item.value}>{item.key}</li>
        ))}
      </ul>
    )
  }

  const render_dependants = () => {
    const selected_obj = dependants_list.filter((item) =>
      get(current_detail_data, 'dependants', []).includes(item.value)
    )
    return (
      <ul>
        {selected_obj.map((item) => (
          <li key={item.value}>{item.key}</li>
        ))}
      </ul>
    )
  }

  const render_estate_planning = (advise_for_type) => {
    let result = null
    if (get(estate_planning, advise_for_type + '_have_wills', '') === '1') {
      result = (
        <div>
          <br />
          <p>
            Based on the information provided, you have existing Will
            {get(estate_planning, advise_for_type + '_wills_date', '') && (
              <span>
                {' '}
                dated {common_date_format(get(estate_planning, advise_for_type + '_wills_date'))}
              </span>
            )}
            {get(estate_planning, advise_for_type + '_wills_date_last_review') && (
              <span>
                {' '}
                which was last reviewed on{' '}
                {common_date_format(
                  get(estate_planning, advise_for_type + '_wills_date_last_review')
                )}
              </span>
            )}
            {get(estate_planning, advise_for_type + '_wills_location') && (
              <span>
                {' '}
                which was located at {get(estate_planning, advise_for_type + '_wills_location')}
              </span>
            )}
            .
          </p>
        </div>
      )
    }
    return result
  }

  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <Tabs type="card">
        <TabPane tab="Strategy" key="1">
          <div>
            <h1 className="flex_title">
              <span className="title">Recommendations</span>
              <div>
                <Button style={{ marginRight: 15 }} type="primary" onClick={onClickMainSave}>
                  Save
                </Button>
                <span style={{ paddingRight: 15 }}>Advice for</span>
                <Select
                  mode="single"
                  name="advice_for"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Advice for"
                  defaultValue="client1"
                >
                  {render_advise_for_option(true)}
                </Select>
              </div>
            </h1>
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="knowledge_area">
                  {/* Droppable 1 */}
                  <div>
                    <Droppable droppableId="droppable1" direction="vertical">
                      {(provided, snapshot) => (
                        <div
                          style={{ marginTop: 0 }}
                          className={
                            snapshot.isDraggingOver
                              ? 'cards_knowledge_sidebar dropping'
                              : 'cards_knowledge_sidebar'
                          }
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <h4 style={{ paddingLeft: 10 }}>Saved for later</h4>
                          {state.droppable1.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  className="stratergy_box_container"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    background: `linear-gradient(to right, ${get(item, 'area.color', '#808080')}, ${get(item, 'area.color2', '#808080')})`,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="stratergy_card">
                                    <div className="stratergy_card_summary">
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <span
                                          className="material-icons-outlined"
                                          style={{
                                            width: 24,
                                            fontSize: '24px',
                                            marginRight: '5px',
                                          }}
                                        >
                                          {get(item, 'area.icon', '')}
                                        </span>
                                        <h3 style={{ margin: '0' }}>{get(item, 'subject')}</h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Droppable droppableId="droppable3" direction="vertical">
                      {(provided, snapshot) => (
                        <div
                          className={
                            snapshot.isDraggingOver
                              ? 'cards_knowledge_sidebar dropping'
                              : 'cards_knowledge_sidebar'
                          }
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <h4 style={{ paddingLeft: 10 }}>Remaining item</h4>
                          {state.droppable3.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  className="stratergy_box_container"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    background: `linear-gradient(to right, ${get(item, 'area.color', '#808080')}, ${get(item, 'area.color2', '#808080')})`,
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="stratergy_card">
                                    <div className="stratergy_card_summary">
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <h3 style={{ margin: '0' }}>{get(item, 'subject')}</h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>

                  {/* Droppable 2 */}
                  <Droppable droppableId="droppable2" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        className={
                          snapshot.isDraggingOver
                            ? 'cards_knowledge_container'
                            : 'cards_knowledge_container'
                        }
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <h4 style={{ paddingLeft: 10 }}>Selected Strategy Attributes</h4>
                        {state.droppable2.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                onClick={() => toggleItemClicked(item.id)}
                                className={`${snapshot.isDragging ? 'knowledge_box cards_knowledge_box flip-card is_dragging' : 'knowledge_box cards_knowledge_box flip-card'} ${clickedItems.includes(item.id) ? 'clicked' : ''}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  background: `linear-gradient(to right, ${get(item, 'area.color', '#808080')}, ${get(item, 'area.color2', '#808080')})`,
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <div className="flip-card-inner">
                                  <div className="flip-card-front">
                                    {get(item, 'area.icon', '') && (
                                      <span
                                        className="material-icons-outlined"
                                        style={{
                                          width: 24,
                                          fontSize: '24px',
                                          marginRight: '5px',
                                        }}
                                      >
                                        {get(item, 'area.icon', '')}
                                      </span>
                                    )}
                                    <h3 style={{ margin: '0' }}>
                                      {get(item, 'subject')} for{' '}
                                      {get(
                                        advise_for_option,
                                        get(item, 'advise_for', 'client1'),
                                        ''
                                      )}
                                    </h3>
                                    <div>
                                      <p></p>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: get(item, 'intro'),
                                      }}
                                    />
                                    <div>
                                      <p></p>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: get(item, 'body_text'),
                                      }}
                                    />
                                    <div>
                                      <p></p>
                                    </div>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: get(item, 'summary'),
                                      }}
                                    />
                                  </div>
                                  <div className="flip-card-back">
                                    <div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <span
                                          className="material-icons-outlined"
                                          style={{
                                            width: 24,
                                            fontSize: '24px',
                                            marginRight: '5px',
                                          }}
                                        >
                                          {get(item, 'area.icon', '')}
                                        </span>
                                        <h3
                                          style={{
                                            margin: '0',
                                            color: 'white',
                                          }}
                                        >
                                          {get(item, 'subject')} for{' '}
                                          {get(
                                            advise_for_option,
                                            get(item, 'advise_for', 'client1'),
                                            ''
                                          )}
                                        </h3>
                                      </div>
                                      <div>
                                        <p></p>
                                      </div>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: get(item, 'description'),
                                        }}
                                      />
                                    </div>
                                    <Button type="primary" onClick={(e) => showModal(e, index)}>
                                      Open Modal
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            </div>
            <Modal
              title="Strategy"
              width={'100%'}
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                !edit && (
                  <Button key="edit" icon={<EditOutlined />} size="medium" onClick={onClickEdit}>
                    Edit
                  </Button>
                ),
                !edit ? (
                  <Button key="submit" type="primary" onClick={handleOkModalSave}>
                    Save and Exit
                  </Button>
                ) : (
                  <Button size="medium" type="primary" onClick={onClickUpdate}>
                    Update
                  </Button>
                ),
              ]}
              closable={false}
              maskClosable={false}
            >
              <Tabs type="card">
                <TabPane tab="Main" key="1">
                  {!edit && (
                    <div>
                      <h3>
                        Advice for{' '}
                        {get(
                          advise_for_option,
                          get(current_update_data, 'advise_for', 'client1'),
                          ''
                        )}
                      </h3>
                      <br />
                      <h3>Our strategy</h3>
                      <p>
                        We recommend that you{' '}
                        <b>{firstLetterToLowerCase(get(current_detail_data, 'subject', ''))}</b>
                      </p>
                      <br />
                      {get(current_detail_data, 'dependants', []).length > 0 && (
                        <div>
                          <p>You wish to consider the dependant(s) below</p>
                          {render_dependants()}
                          <br />
                        </div>
                      )}
                      <InvestmentOptionTable netwealth_type='asset' advice_strategy_id={current_detail_data.id} product_type={current_detail_data.product_type} strategy_history={history_id}/>
                      {/*todo current_detail_data.product_type from full serilaizer*/}
                      <InvestmentOptionTable netwealth_type='liability' advice_strategy_id={current_detail_data.id} product_type={current_detail_data.product_type} strategy_history={history_id}/>
                      <p>
                        To be implemented{' '}
                        {getImplementationText(
                          current_update_data.implemented,
                          current_update_data.implementation_date
                        )}
                      </p>
                      <br />
                      <h3>This strategy will provide you with the following benefits</h3>
                      <ul>
                        {get(current_detail_data, 'benefits', [])
                          .filter((item) =>
                            get(current_detail_data, 'selected_benefits', []).includes(item.id)
                          )
                          .map((item) => (
                            <li key={item.id}>{item.benefit.subject}</li>
                          ))}
                      </ul>
                      {!isQuillEmpty(get(current_detail_data, 'pros', '')) ||
                      get(current_detail_data, 'selected_benefits', []).length > 0 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: get(current_detail_data, 'pros', ''),
                          }}
                        />
                      ) : (
                        <p>-</p>
                      )}
                      <br />
                      {get(current_detail_data, 'goals', []).length > 0 && (
                        <div>
                          <p>and help you achieve the following goals</p>
                          {render_goal()}
                          <br />
                        </div>
                      )}
                      <h3>The risks for implementing this strategy</h3>
                      <ul>
                        {get(current_detail_data, 'risks', [])
                          .filter((item) =>
                            get(current_detail_data, 'selected_risks', []).includes(item.id)
                          )
                          .map((item) => (
                            <li key={item.id}>{item.risk.subject}</li>
                          ))}
                      </ul>
                      {!isQuillEmpty(get(current_detail_data, 'cons', '')) ||
                      get(current_detail_data, 'selected_risks', []).length > 0 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: get(current_detail_data, 'cons', ''),
                          }}
                        />
                      ) : (
                        <p>-</p>
                      )}
                    </div>
                  )}
                  {edit && (
                    <Form layout="vertical" className={'custom_modal_form'}>
                      <Form.Item label="Advice for">
                        <Select
                          mode="single"
                          name="advise_for"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Advice for"
                          defaultValue="client1"
                          onChange={(value) => onChangeUpdate('advise_for', value)}
                          value={current_update_data.advise_for}
                        >
                          {render_advise_for_option()}
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        {render_estate_planning(current_update_data.advise_for)}
                      </Form.Item>
                      <Form.Item label="We recommend that you">
                        <Input
                          style={{ width: '100%' }}
                          placeholder="We recommend that you"
                          name="subject"
                          onChange={(e) => onChangeUpdate('subject', e.target.value)}
                          value={current_update_data.subject}
                        />
                      </Form.Item>
                      <Form.Item label="Select the dependants below">
                        <CheckboxGroup
                          onChange={(checkedValues) => onChangeUpdate('dependants', checkedValues)}
                          value={current_update_data.dependants}
                        >
                          {dependants_list.map((option) => (
                            <div
                              key={option.value}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                              }}
                            >
                              <Checkbox value={option.value} style={{ marginRight: '8px' }} />
                              <span>{option.key}</span>
                            </div>
                          ))}
                        </CheckboxGroup>
                      </Form.Item>
                      <Form.Item label="When will it be implemented">
                        <Select
                          mode="single"
                          name="implemented"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="When will it be implemented"
                          value={current_update_data.implemented}
                          onChange={(value) => onChangeUpdate('implemented', value)}
                        >
                          <Option value=""></Option>
                          {Object.entries(implemented_option).map(
                            ([key, value]) =>
                              !(current_index === 0 && key === 'other_strategy') && (
                                <Option key={key} value={key}>
                                  {value}
                                </Option>
                              )
                          )}
                        </Select>
                      </Form.Item>
                      {current_update_data.implemented === 'date_specific' && (
                        <Form.Item label="Implementation date">
                          <Input
                            style={{ width: '100%' }}
                            type="date"
                            placeholder="Implementation date"
                            name="implementation_date"
                            onChange={(e) => onChangeUpdate('implementation_date', e.target.value)}
                            value={current_update_data.implementation_date}
                          />
                        </Form.Item>
                      )}
                      <Form.Item label="This strategy will provide you with the following benefits">
                        <CheckboxGroup
                          onChange={(checkedValues) =>
                            onChangeUpdate('selected_benefits', checkedValues)
                          }
                          value={current_update_data.selected_benefits}
                        >
                          {current_detail_data.benefits.map((option) => (
                            <div
                              key={option.id}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                              }}
                            >
                              <Checkbox
                                value={option.id}
                                style={{ marginRight: '8px' }}
                                disabled={option.required === 'yes'}
                              />
                              <span>{option.benefit.subject}</span>
                            </div>
                          ))}
                        </CheckboxGroup>
                      </Form.Item>
                      <Form.Item>
                        <QuillEditor
                          theme="snow"
                          name="pros"
                          value={current_update_data.pros}
                          onChange={(value) => onChangeUpdate('pros', value)}
                        />
                        <Form.Item label="and help you achieve the following goals">
                          <CheckboxGroup
                            onChange={(checkedValues) => onChangeUpdate('goals', checkedValues)}
                            value={current_update_data.goals}
                          >
                            {goals_list.map((option) => (
                              <div
                                key={option.value}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginBottom: '8px',
                                }}
                              >
                                <Checkbox value={option.value} style={{ marginRight: '8px' }} />
                                <span>{option.key}</span>
                              </div>
                            ))}
                          </CheckboxGroup>
                        </Form.Item>
                      </Form.Item>
                      <Form.Item label="The risks for implementing this strategy">
                        <CheckboxGroup
                          onChange={(checkedValues) =>
                            onChangeUpdate('selected_risks', checkedValues)
                          }
                          value={current_update_data.selected_risks}
                        >
                          {current_detail_data.risks.map((option) => (
                            <div
                              key={option.id}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '8px',
                              }}
                            >
                              <Checkbox
                                value={option.id}
                                style={{ marginRight: '8px' }}
                                disabled={option.required === 'yes'}
                              />
                              <span>{option.risk.subject}</span>
                            </div>
                          ))}
                        </CheckboxGroup>
                        <QuillEditor
                          theme="snow"
                          name="cons"
                          value={current_update_data.cons}
                          onChange={(value) => onChangeUpdate('cons', value)}
                        />
                      </Form.Item>
                    </Form>
                  )}
                </TabPane>
                <TabPane tab="Next steps" key="2">
                  {!edit && (
                    <Table
                      className="custom_responsive_table"
                      columns={implemented_columns}
                      dataSource={advice_implementation_steps}
                      rowKey="id"
                      pagination={{ defaultPageSize: 50 }}
                    />
                  )}
                  {edit && (
                    <div>
                      <Row gutter={16} className="compact_rows header-row">
                        <Col span={17}>
                          <strong>Implementation Step</strong>
                        </Col>
                        <Col span={3}>
                          <strong>Action by</strong>
                        </Col>
                        <Col span={3}>
                          <strong>Timing</strong>
                        </Col>
                      </Row>
                      {advice_implementation_steps.map((b, index) => (
                        <Row key={index} gutter={16} className={'compact_rows'}>
                          <Col span={17}>
                            <Form.Item>
                              <Select
                                mode="single"
                                name="implementation_step"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select step"
                                value={b.implementation_step.id}
                                onChange={(value) =>
                                  handleAdviceImplementationStepsChange(
                                    index,
                                    'implementation_step',
                                    { id: value }
                                  )
                                }
                                disabled={b.id ? true : false}
                              >
                                {implementation_steps_list.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.subject}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item>
                              <Select
                                mode="single"
                                name="action_by"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="select action by"
                                value={b.action_by}
                                onChange={(value) =>
                                  handleAdviceImplementationStepsChange(index, 'action_by', value)
                                }
                              >
                                <Option value=""></Option>
                                {Object.entries(action_by_option).map(([key, value]) => (
                                  <Option key={key} value={key}>
                                    {value}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item>
                              <Select
                                mode="single"
                                name="timing"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="select timing"
                                value={b.timing}
                                onChange={(value) =>
                                  handleAdviceImplementationStepsChange(index, 'timing', value)
                                }
                              >
                                <Option value=""></Option>
                                {Object.entries(timing_option).map(([key, value]) => (
                                  <Option key={key} value={key}>
                                    {value}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          {get(b, 'required', '') !== 'yes' && (
                            <Col span={1} className={'delete_btn_column'}>
                              <Button
                                danger
                                icon={<DeleteOutlined />}
                                size="medium"
                                onClick={() => handleRemoveImplementationSteps(index)}
                              />
                            </Col>
                          )}
                        </Row>
                      ))}
                      <Button
                        type="dashed"
                        onClick={handleAddImplementationSteps}
                        style={{ width: '100%', marginBottom: '20px' }}
                      >
                        Add implementation step
                      </Button>
                    </div>
                  )}
                </TabPane>
                {get(current_detail_data, 'intro_body_summary') && (
                  <TabPane tab="How does it works" key="3">
                    {!edit && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: get(current_detail_data, 'intro_body_summary'),
                        }}
                      />
                    )}
                    {edit && (
                      <Form layout="vertical" className={'custom_modal_form'}>
                        <Form.Item label="How this works">
                          <QuillEditor
                            theme="snow"
                            name="intro_body_summary"
                            value={current_update_data.intro_body_summary}
                            onChange={(value) => onChangeUpdate('intro_body_summary', value)}
                          />
                        </Form.Item>
                      </Form>
                    )}
                  </TabPane>
                )}
              </Tabs>
            </Modal>
          </div>
        </TabPane>
        <TabPane tab="Movement of funds" key="4">
+       </TabPane>
        <TabPane tab="Additional information" key="2">
          <h1 className="flex_title">
            <span className="title"></span>
            <Button type="primary" onClick={onClickAdditionalSave}>
              save
            </Button>
          </h1>
          <Form layout="vertical" className={'custom_modal_form'}>
            <Form.Item label="Advice type">
              <Select
                mode="single"
                name="advice_type"
                allowClear
                style={{ width: '100%' }}
                placeholder="Advice type"
                value={additional_info.advice_type}
                onChange={onChangeAdviceType}
              >
                <Option value=""></Option>
                {doc_list.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.subject}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Advice name">
              <Input
                style={{ width: '100%' }}
                placeholder="Advice name"
                name="advice_name"
                value={additional_info.advice_name}
                onChange={additionInfoHandleChange}
              />
            </Form.Item>
            <Form.Item label="Advice date">
              <Input
                style={{ width: '100%' }}
                placeholder="Advice date"
                name="advice_date"
                type="date"
                value={additional_info.advice_date}
                onChange={additionInfoHandleChange}
              />
            </Form.Item>
            <Form.Item label="Created date">
              <Input
                style={{ width: '100%' }}
                placeholder="Created date"
                name="created_on"
                type="date"
                value={moment(detail_data.created_on).format('YYYY-MM-DD')}
                disabled
              />
            </Form.Item>
            <Form.Item label="Client information date">
              {detail_data.wizard_history_obj && (
                <a>
                  {' '}
                  {detail_data.wizard_history_obj.name}, created on{' '}
                  {moment(detail_data.wizard_history_obj.created_on).format('YYYY-MM-DD')}{' '}
                </a>
              )}
            </Form.Item>
          </Form>
        </TabPane>
        <TabPane tab="fees and charges" key="3">
          <h1 className="flex_title">
            <span className="title"></span>
            <Button type="primary" onClick={onClickFeesSave}>
              save
            </Button>
          </h1>
          <Form layout="vertical" className={'custom_modal_form'}>
            <Form.Item label="Plan fee">
              <Input
                style={{ width: '15%' }}
                placeholder="Plan fee"
                name="plan_fee"
                type="number"
                value={fees_charges.plan_fee}
                onChange={feesChargeInfoHandleChange}
              />
            </Form.Item>
            <Form.Item label="Implementation fee">
              <Input
                style={{ width: '15%' }}
                placeholder="Implementation fee"
                name="implementation_fee"
                type="number"
                value={fees_charges.implementation_fee}
                onChange={feesChargeInfoHandleChange}
              />
            </Form.Item>
            <Form.Item label="Ongoing service fee">
              <Input
                style={{ width: '15%' }}
                placeholder="Ongoing service fee"
                name="ongoing_service_fee"
                type="number"
                value={fees_charges.ongoing_service_fee}
                onChange={feesChargeInfoHandleChange}
              />
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>
  )

  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default AdviceStrategyScenario
